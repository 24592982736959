export const PHYSICAL = 'physical';
export const CHEMICAL = 'chemical';
export const BIOLOGICAL = 'biological';
export const ERGONOMIC = 'ergonomic';
export const ACCIDENT = 'accident';

export const types = {
  [PHYSICAL]: 'Físico',
  [CHEMICAL]: 'Químico',
  [BIOLOGICAL]: 'Biológico',
  [ERGONOMIC]: 'Ergonômico',
  [ACCIDENT]: 'Acidente',
};

export function getName(value) {
  return types[value];
}
