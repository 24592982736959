<template>
  <div class="page-container clients-page">
    <portal to="page-name">ASO</portal>
    <div class="card card-page">
      <div class="card-header">
        <div class="float-right d-flex">
          <button
            class="btn btn-primary btn-icon btn-icon-left mr-2"
            @click="create"
          ><fa-icon :icon="['fal', 'plus']"/> Novo ASO</button>
          <st-button-report
            type="sheet"
            button-class="btn-gray"
            route="/asos/reports"
            :params="filterParams"
          ></st-button-report>
        </div>
        <h1 class="card-title">ASO's</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-4 col-md-6 col-md-12 form-group">
            <select class="form-select" v-model="filter.clientId">
              <option value="">[Todas empresas]</option>
              <option
                v-for="client in clients"
                :value="client.id"
                :key="client.id"
              >{{ client.companyName }} ({{ client.name }})</option>
            </select>
          </div>
          <div class="column col-4 col-md-6 col-sm-12 form-group"
               :class="{'has-error': $v.filter.startDate.$error || $v.filter.endDate.$error}">
            <div class="input-group">
              <dx-dropdown
                class="input-group-addon"
                :items="periodOptions"
                label="text"
                @select="setPeriod"
                direction="left"
              ><fa-icon :icon="['fal', 'chevron-down']" /></dx-dropdown>
              <dx-input-date
                id="start-date"
                class="form-input text-center"
                v-model="filter.startDate"
                @change="changedStartDate"
                @blur="$v.filter.startDate.$touch()"
              />
              <dx-input-date
                id="end-date"
                class="form-input text-center"
                v-model="filter.endDate"
                @change="changedEndDate"
                @blur="$v.filter.endDate.$touch()"
              />
            </div>
          </div>
          <div class="column form-group">
            <div class="has-icon-right">
              <input
                type="text"
                id="filter-search"
                name="filter-search"
                class="form-input"
                v-model="filter.search"
                maxlength="100"
                autocomplete="nope"
                placeholder="Busca por nome do funcionário"
                v-focus
              >
              <fa-icon :icon="['fal', 'search']" class="form-icon"/>
            </div>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading" />
        <template v-else>
          <div class="empty mt-2" v-if="data.items && data.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">ASO's</p>
            <p class="empty-subtitle">
              Nenhum ASO encontrado. Verifique os filtros para realizar novas buscas
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th style="width:40px">#</th>
              <th>Funcionário</th>
              <th>Empresa</th>
              <th>Tipo</th>
              <th>Data</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in data.items" :key="i">
              <td>{{ i + 1 }}</td>
              <td>
                <router-link
                  :to="editLink(item)"
                >{{ item.employee.name }}</router-link>
              </td>
              <td>{{ item.company.companyName }}</td>
              <td>{{ getTypeName(item.type) }}</td>
              <td>{{ item.date | date('date', true) }}</td>
              <td class="text-right">
                <button class="btn btn-sm btn-action btn-icon btn-secondary tooltip"
                        @click="edit(item)" data-tooltip="Editar">
                  <fa-icon :icon="['fal', 'pencil']"/>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <div class="card-footer text-center" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary btn-fixed-width"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { getName } from '@/data/aso-types';
import { parsePeriod } from '@/helpers/date';
import { date, minDate } from '@/data/validators';

export default {
  data() {
    return {
      path: '/asos',
      filter: {
        search: '',
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        clientId: '',
      },
      debounceTimeout: null,
      loading: false,
      loadingMore: false,
      data: {
        items: [],
        hasMore: false,
        limit: 30,
        offset: 0,
      },
      periodOptions: [
        { value: 'today', text: 'Hoje' },
        { value: 'week', text: 'Esta semana' },
        { value: 'd:-7', text: 'Últimos 7 dias' },
        { value: 'd:-15', text: 'Últimos 15 dias' },
        { value: 'month', text: 'Este mês' },
      ],
      clients: [],
    };
  },
  validations() {
    const rules = {
      filter: {
        startDate: { required, date },
        endDate: { required, date },
      },
    };

    if (this.filter.startDate && moment(this.filter.startDate).isValid()) {
      rules.filter.endDate = {
        required,
        date,
        minDate: minDate(this.filter.startDate),
      };
    }

    return rules;
  },
  mounted() {
    this.load();
    this.loadClients();
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.reload, 1000);
      },
      deep: true,
    },
  },
  computed: {
    filterParams() {
      const params = {
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
      };
      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search.trim();
      }
      if (this.filter.clientId) {
        params.clientId = this.filter.clientId;
      }
      return params;
    },
  },
  methods: {
    async reload() {
      this.data.offset = 0;
      await this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    setPeriod({ value }) {
      const { start, end } = parsePeriod(value);
      this.filter.startDate = start;
      this.filter.endDate = end;
    },
    load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
        ...this.filterParams,
      };

      return this.$http
        .get(this.path, { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    async loadClients() {
      const params = {
        offset: 0,
        limit: 0,
      };

      try {
        const { data } = await this.$http.get('/clients', { params });
        this.clients = data.items;
      } catch (e) {
        this.$toast.error(e);
      }
    },
    getTypeName(type) {
      return getName(type);
    },
    create() {
      this.$router.push(`${this.path}/create`);
    },
    edit(item) {
      this.$router.push(`${this.path}/${item.id}/edit`);
    },
    editLink(item) {
      return `${this.path}/${item.id}/edit`;
    },
    changedStartDate() {
      if (!this.filter.startDate) {
        this.filter.startDate = moment().format('YYYY-MM-DD');
        this.filter.endDate = moment().format('YYYY-MM-DD');
      }
    },
    changedEndDate() {
      if (!this.filter.startDate && !this.filter.endDate) {
        this.filter.startDate = moment().format('YYYY-MM-DD');
        this.filter.endDate = moment().format('YYYY-MM-DD');
      }
      if (!this.filter.endDate) {
        this.filter.endDate = this.filter.startDate;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.clients-page {
  .btn-fixed-width {
    font-size: $font-size-lg;
    height: 2.2rem;
    margin: $layout-spacing-lg 0;
    width: 11rem;
  }
}
</style>
