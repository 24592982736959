export const ADMISSION = 'admission';
export const DISMISSAL = 'dismissal';
export const PERIODICAL = 'periodical';
export const BACK_TO_WORK = 'back_to_work';
export const ROLE_CHANGE = 'role_change';

export const types = {
  [ADMISSION]: 'Admissional',
  [DISMISSAL]: 'Demissional',
  [PERIODICAL]: 'Periódico',
  [BACK_TO_WORK]: 'Retorno ao trabalho',
  [ROLE_CHANGE]: 'Mudança de função',
};

export function getName(value) {
  return types[value];
}
