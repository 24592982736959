<template>
  <div class="page-container aso-page">
    <portal to="page-name">ASO</portal>

    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">ASO</h1>
      </div>

      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-4 col-md-5 col-sm-12">
            <label for="status" class="form-label">Tipo</label>
            <select id="status" name="status" class="form-select"
                    v-model="form.type">
              <option v-for="(text, value) in types" :value="value" :key="value">
                {{ text }}
              </option>
            </select>
          </div>
          <div
            class="column col-3 form-group"
            :class="{'has-error': $v.form.date.$error}"
          >
            <label class="form-label">Data de admissão</label>
            <st-input-date class="form-input" v-model="form.date"></st-input-date>
            <template v-if="$v.form.date.$error">
              <div class="form-input-hint" v-if="!$v.form.date.required">Campo obrigatório</div>
              <div class="form-input-hint" v-else-if="!$v.form.date.date">Data inválida</div>
            </template>
          </div>
          <div class="column col-3 form-group">
            <label class="form-label">Data do ASO</label>
            <st-input-date
              class="form-input"
              :value="createdAt"
              :readonly="true"
            ></st-input-date>
          </div>

          <!-- COMPANY -->
          <div
            class="column col-6 form-group"
            :class="{'has-error': $v.form.company.id.$error}"
          >
            <label class="form-label">Empresa</label>
            <dx-autocomplete
              id="company-name"
              v-model="company"
              :source="findCompany"
              label="companyName"
              track-by="id"
              :readonly="isSetCompany"
              @select="setCompany"
              :debounce="800"
              placeholder="Nome da empresa"
            >
              <template v-slot:action>
                <button
                  v-if="isSetCompany"
                  @click="unsetCompany"
                  class="btn btn-action input-group-btn btn-icon btn-gray"
                  tabindex="-1">
                  <fa-icon :icon="['fal', 'times']" />
                </button>
                <button
                  v-else
                  class="btn btn-action input-group-btn btn-icon btn-neutral"
                  tabindex="-1">
                  <fa-icon :icon="['fal', 'search']" />
                </button>
              </template>
              <template v-slot="{ item }">
                <div>{{ item.companyName }} ({{ item.name }})</div>
                <small class="text-primary" v-if="item.id === null">
                  (empresa não cadastrada)
                </small>
              </template>
            </dx-autocomplete>
            <template v-if="$v.form.company.id.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.company.id.required"
              >Campo obrigatório</div>
            </template>
          </div>

          <!-- EMPLOYEE -->
          <div
            class="column col-6 form-group"
            :class="{'has-error': $v.form.employee.id.$error}"
          >
            <label class="form-label">Funcionário</label>
            <dx-autocomplete
              id="employee-name"
              v-model="employee"
              :source="findEmployee"
              label="name"
              track-by="id"
              :readonly="!!form.employee.id"
              @select="setEmployee"
              @blur="onEmployeeBlur"
              :debounce="800"
              placeholder="Nome, CPF ou data de nascimento"
            >
              <template v-slot:action>
                <button
                  @click="unsetEmployee"
                  class="btn btn-action input-group-btn btn-icon"
                  :class="employee ? 'btn-gray' : 'btn-neutral'"
                  tabindex="-1"
                ><fa-icon :icon="['fal', employee ? 'times' : 'search']"></fa-icon></button>
              </template>
              <template v-slot="{ item }">
                <div>{{ item.name }}</div>
                <small class="text-primary" v-if="item.id === null">
                  (paciente sem cadastro)
                </small>
                <small class="text-primary" v-else>
                  <fa-icon :icon="['fal', 'gift']"></fa-icon>
                  {{ item.birthDate | date }} ({{ item.birthDate | dateOld }})
                </small>
              </template>
            </dx-autocomplete>
            <template v-if="$v.form.employee.id.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.employee.id.required"
              >Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-3 form-group"
               :class="{'has-error': $v.employeeCPF.$error}">
            <label class="form-label">CPF</label>
            <input
              type="text"
              class="form-input"
              v-model="employeeCPF"
              @blur="$v.employeeCPF.$touch()"
              placeholder="000.000.000-00"
              v-mask-cpf
            >
            <template v-if="$v.employeeCPF.$error">
              <div class="form-input-hint"
                   v-if="!$v.employeeCPF.cpf">CPF inválido</div>
            </template>
          </div>
          <div class="column col-3 form-group">
            <label class="form-label">RG</label>
            <input type="text" class="form-input" v-model="employeeRG">
          </div>
          <div class="column col-3 form-group">
            <label class="form-label">Setor</label>
            <input
              type="text"
              class="form-input"
              v-model="form.employee.sector"
              placeholder="Setor"
            >
          </div>
          <div class="column col-3 form-group">
            <label class="form-label">Cargo</label>
            <input
              type="text"
              class="form-input"
              v-model="form.employee.position"
              placeholder="Cargo"
            >
          </div>

          <!-- PROFESSIONAL -->
          <div
            class="column col-6 form-group"
            :class="{'has-error': $v.form.professional.id.$error}"
          >
            <label class="form-label">Profissional da saúde</label>
            <dx-autocomplete
              id="professional-name"
              v-model="professional"
              :source="findProfessional"
              label="name"
              track-by="id"
              @select="setProfessional"
              :debounce="800"
              :readonly="isSetProfessional"
              placeholder="Nome do profissional"
            >
              <template v-slot:action>
                <button
                  v-if="isSetProfessional"
                  @click="unsetProfessional"
                  class="btn btn-action input-group-btn btn-icon btn-gray"
                  tabindex="-1">
                  <fa-icon :icon="['fal', 'times']" />
                </button>
                <button
                  v-else
                  class="btn btn-action input-group-btn btn-icon btn-neutral"
                  tabindex="-1">
                  <fa-icon :icon="['fal', 'search']" />
                </button>
              </template>
              <template v-slot="{ item }">
                <div>{{ item.name }}</div>
                <small class="text-primary" v-if="item.id === null">
                  (profissional não cadastrado)
                </small>
              </template>
            </dx-autocomplete>
            <template v-if="$v.form.professional.id.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.professional.id.required"
              >Campo obrigatório</div>
            </template>
          </div>

          <!-- COORDINATOR -->
          <div class="column col-6 form-group">
            <label class="form-label">Coordenador</label>
            <dx-autocomplete
              id="coordinator-name"
              v-model="coordinator"
              :source="findProfessional"
              label="name"
              track-by="id"
              @select="setCoordinator"
              :debounce="800"
              :readonly="isSetCoordinator"
              placeholder="Nome do profissional"
            >
              <template v-slot:action>
                <button
                  v-if="isSetCoordinator"
                  @click="unsetCoordinator"
                  class="btn btn-action input-group-btn btn-icon btn-gray"
                  tabindex="-1">
                  <fa-icon :icon="['fal', 'times']" />
                </button>
                <button
                  v-else
                  class="btn btn-action input-group-btn btn-icon btn-neutral"
                  tabindex="-1">
                  <fa-icon :icon="['fal', 'search']" />
                </button>
              </template>
              <template v-slot="{ item }">
                <div>{{ item.name }}</div>
                <small class="text-primary" v-if="item.id === null">
                  (profissional não cadastrado)
                </small>
              </template>
            </dx-autocomplete>
          </div>
        </div>

        <div class="divider" />
        <div class="stenci-title">Fatores de risco</div>
        <div class="columns">
          <div class="column col-auto form-group">
            <select id="status" class="form-select" v-model="riskFactor.type">
              <option v-for="(text, value) in riskTypes" :value="value" :key="value">
                {{ text }}
              </option>
            </select>
          </div>
          <div class="column form-group">
            <div class="input-group">
              <input
                type="text"
                class="form-input"
                v-model="riskFactor.value"
                placeholder="Risco"
                @keypress.enter="addRiskFactor"
              >
              <button
                class="btn btn-icon input-group-btn btn-info"
                @click="addRiskFactor"
              ><fa-icon :icon="['fal', 'plus-circle']"></fa-icon></button>
            </div>
          </div>
        </div>
        <risk-factor-table
          :items="form.riskFactors"
          @remove="removeRiskFactor"
        ></risk-factor-table>

        <div class="divider" />
        <div class="stenci-title">Procedimentos / exames</div>

        <div class="columns">
          <div class="column col-12 form-group"
               :class="{'has-error': $v.form.procedures.$error && !$v.form.procedures.required}">
            <label class="form-label">Procedimento</label>
            <dx-autocomplete
              id="expense-name"
              label="name"
              track-by="id"
              v-model="procedure"
              :source="loadProcedure"
              :disabled="!isSetCompany"
              @select="addProcedure"
              :debounce="800"
              placeholder="Código ou nome do procedimento / exame"
            >
              <template v-slot:action>
                <button class="btn btn-neutral btn-action btn-icon input-group-btn">
<!--                <button class="btn btn-neutral btn-action btn-icon input-group-btn"-->
<!--                        :class="{loading: expenseModal.loading}"-->
<!--                        :disabled="expenseModal.loading || !(partner && partner.id)-->
<!--                            || cashierClosed || form.installments.length > 0"-->
<!--                        @click="openExpenseModal">-->
                  <fa-icon :icon="['fal', 'search']"/>
                </button>
              </template>
            </dx-autocomplete>
            <template v-if="$v.form.procedures.$error">
              <div
                class="form-input-hint"
                v-if="!$v.form.procedures.required"
              >Informe um procedimento</div>
            </template>
          </div>
        </div>

        <div class="empty mt-2" v-if="form.procedures.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
          </div>
          <p class="empty-title h6">Nenhum procedimento adicionado</p>
          <p class="empty-subtitle">
            Selecione um convênio e informe parte do nome ou código do procedimento
          </p>
        </div>
        <table class="table table-hover" v-else>
          <thead>
          <tr>
            <th class="hide-sm" style="width:50px">#</th>
            <th>Procedimento</th>
            <th>Data</th>
            <th>Resultado</th>
            <th style="width:50px"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(procedure, i) in form.procedures" :key="i">
            <td class="hide-sm">{{ i + 1 }}</td>
            <td>{{ procedure.code | tuss }} - {{ procedure.name }}</td>
            <td :class="{'has-error': $v.form.procedures.$each[i].date.$error}">
              <st-input-date
                class="form-input input-sm"
                v-model="procedure.date"
              ></st-input-date>
            </td>
            <td>
              <select class="form-select select-sm" v-model="procedure.result">
                <option value="pending">Pendente</option>
                <option value="normal">Normal</option>
                <option value="altered">Alterado</option>
              </select>
            </td>
            <td class="text-right">
              <button
                class="btn btn-sm btn-action btn-icon btn-error tooltip"
                data-tooltip="Excluir"
                @click="removeProcedure(i)"
              ><fa-icon :icon="['fal', 'times']"></fa-icon></button>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="form-group mt-2">
          <label class="form-switch">
            <input type="checkbox" v-model="form.able">
            <i class="form-icon"></i> Apto?
          </label>
          <template v-if="form.able">
            <label class="form-switch">
              <input type="checkbox"  v-model="form.ableWithRestrictions">
              <i class="form-icon"></i> Apto com restrições
            </label>
            <label class="form-switch">
              <input type="checkbox" v-model="form.ableForHeight">
              <i class="form-icon"></i> Apto para trabalho em altura
            </label>
          </template>
        </div>

        <div class="form-group">
          <label for="" class="form-label">Observações</label>
          <textarea class="form-input" v-model="form.observation"></textarea>
        </div>
      </div>

      <div class="card-footer">
        <button
          class="btn btn-primary"
          :class="{ loading: saving }"
          @click="save"
          :disabled="saving"
        >Salvar</button>
        <button
          v-if="form.id"
          class="btn ml-2 btn-gray"
          :class="{ loading: printing }"
          @click="print"
          :disabled="printing"
        >Imprimir</button>
        <button class="btn ml-2" @click="$router.back()">Voltar</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { capitalizeName } from '@/filters/capitalize';
import formMixin from '@/mixins/form';
import { mergeFrom } from '@/helpers/object';
import { types, ADMISSION } from '@/data/aso-types';
import { types as riskTypes, PHYSICAL } from '@/data/aso-risk-types';
import { date, cpf } from '@/data/validators';
import RiskFactorTable from './RiskFactorTable.vue';

export default {
  mixins: [formMixin],
  components: {
    RiskFactorTable,
  },
  data() {
    return {
      company: null,
      employee: null,
      professional: null,
      coordinator: null,
      procedure: null,
      form: this.blankForm(),
      createdAt: moment().format('YYYY-MM-DD'),
      riskFactor: {
        type: PHYSICAL,
        value: '',
      },
      loading: false,
      saving: false,
      printing: false,
      types,
      riskTypes,
    };
  },
  validations() {
    const rules = {
      employeeCPF: { cpf },
      form: {
        date: { required, date },
        company: {
          id: { required },
        },
        employee: {
          id: { required },
        },
        professional: {
          id: { required },
        },
        procedures: {
          required,
          $each: {
            date: { date },
          },
        },
      },
    };

    return rules;
  },
  mounted() {
    this.init();
  },
  computed: {
    employeeCPF: {
      get() {
        return this.getEmployeeIdentity('cpf');
      },
      set(value) {
        return this.setEmployeeIdentity('cpf', this.onlyNumbers(value));
      },
    },
    employeeRG: {
      get() {
        return this.getEmployeeIdentity('rg');
      },
      set(value) {
        return this.setEmployeeIdentity('rg', value);
      },
    },
    isSetCompany() {
      return !!(this.company && this.company.id);
    },
    isSetProfessional() {
      return !!(this.professional && this.professional.id);
    },
    isSetCoordinator() {
      return !!(this.coordinator && this.coordinator.name);
    },
  },
  methods: {
    async init() {
      if (this.$route.params.id) {
        this.form.id = this.$route.params.id;
        await this.load();
      } else if (this.$route.query.appointmentId) {
        await this.loadDataFromAppointment(this.$route.query.appointmentId);
      }
    },
    async load() {
      this.loading = true;

      try {
        const { data } = await this.$http.get(`/asos/${this.form.id}`);
        if (data.date) {
          data.date = data.date.substring(0, 10);
        }
        if (Array.isArray(data.procedures) && data.procedures.length > 0) {
          data.procedures = data.procedures.map((proc) => {
            proc.date = proc.date ? proc.date.substring(0, 10) : null;
            return proc;
          });
        }
        this.form = mergeFrom(this.blankForm(), data);
        this.company = data.company;
        this.employee = data.employee;
        this.professional = data.professional;
        this.coordinator = data.coordinator;
        this.createdAt = moment(data.createdAt).format('YYYY-MM-DD');
      } catch (e) {
        this.$toast.error(e);
      }

      this.loading = false;
    },
    async loadDataFromAppointment(appointmentId) {
      this.loading = true;

      try {
        const { data } = await this.$http.get(`/appointments/${appointmentId}`);
        if (data.patient.id) {
          const { data: patient } = await this.$http.get(`/entities/${data.patient.id}`);
          const employee = {
            id: patient.id,
            name: patient.name,
            birthDate: patient.birthDate,
            gender: patient.gender,
            identities: [],
          };
          if (patient.identity) {
            employee.identities.push(patient.identity);
          }
          if (patient.secondaryIdentity) {
            employee.identities.push(patient.secondaryIdentity);
          }
          this.employee = employee;
          this.form.employee = employee;
        }
        this.professional = data.professional;
        this.form.professional = data.professional;
        data.expenses.forEach(this.addProcedure);
      } catch (e) {
        this.$toast.error(e);
      }

      this.loading = false;
    },
    validate() {
      this.$v.$touch();
      return !this.$v.$error;
    },
    async save() {
      if (!this.validate()) {
        return;
      }

      this.saving = true;

      const formData = {
        date: this.form.date,
        type: this.form.type,
        status: this.form.status,
        able: this.form.able,
        professionalId: this.form.professional ? this.form.professional.id : null,
        coordinator: this.form.coordinator,
        companyId: this.form.company ? this.form.company.id : null,
        employee: this.form.employee
          ? {
            id: this.form.employee.id,
            identities: this.form.employee.identities.filter(({ value }) => !!value),
            sector: this.form.employee.sector,
            position: this.form.employee.position,
          }
          : null,
        procedures: this.form.procedures,
        riskFactors: this.form.riskFactors,
        ableWithRestrictions: this.form.ableWithRestrictions,
        ableForHeight: this.form.ableForHeight,
        observation: this.form.observation,
      };

      try {
        const request = this.form.id
          ? this.$http.put(`/asos/${this.form.id}`, formData)
          : this.$http.post('/asos', formData);
        const { data } = await request;
        if (!this.form.id) {
          this.form.id = data.id;
          await this.$router.replace(`/asos/${data.id}/edit`);
        }
        this.$toast.success('Registro salvo');
      } catch (e) {
        this.$toast.error(e);
      }

      this.saving = false;
    },
    async print() {
      this.printing = true;
      try {
        await this.$file.print(`/asos/${this.form.id}/print`);
      } catch (e) {
        this.$toast.error(e);
      }
      this.printing = false;
    },
    async findCompany(search) {
      const { items } = await this.apiGetCompanyList({ search });
      return items.length === 0 ? [{ id: null, name: search }] : items;
    },
    setCompany(data) {
      this.company = data;
      this.form.company = {
        id: data.id,
        name: data.name,
        companyName: data.companyName || null,
      };
      if (data.client?.coordinator?.name) {
        this.setCoordinator(data.client.coordinator);
      }
    },
    unsetCompany() {
      this.company = null;
    },
    async findEmployee(search) {
      const { items } = await this.apiGetEmployeeList({ search });
      return items.length === 0 ? [{ id: null, name: search }] : items;
    },
    setEmployee(data) {
      this.employee = data;
      this.form.employee = {
        ...this.blankEmployeeForm(),
        id: data.id,
        name: data.name,
        identities: [],
        birthDate: data.birthDate,
        gender: data.gender,
      };

      if (data.identity) {
        this.form.employee.identities.push(data.identity);
      }
      if (data.secondaryIdentity) {
        this.form.employee.identities.push(data.secondaryIdentity);
      }
    },
    unsetEmployee() {
      this.employee = null;
      this.form.employee = this.blankEmployeeForm();
    },
    onEmployeeBlur(text) {
      this.form.employee.name = text;
      this.$v.form.employee.$touch();
    },
    async findProfessional(search) {
      const { items } = await this.apiGetProfessionalList({ search });
      return items.length === 0 ? [{ id: null, name: search, council: null }] : items;
    },
    setProfessional(data) {
      this.professional = data;
      this.form.professional = {
        id: data.id,
        name: data.name,
      };
      if (!this.form.coordinator) {
        this.setCoordinator(data);
      }
    },
    unsetProfessional() {
      this.professional = null;
      this.form.professional = null;
    },
    setCoordinator(data) {
      this.coordinator = data;
      this.form.coordinator = {
        id: data.id,
        name: data.name,
        council: data.council,
      };
    },
    unsetCoordinator() {
      this.coordinator = null;
      this.form.coordinator = null;
    },
    async loadProcedure(search) {
      const { items } = await this.apiGetProcedureList({ search });
      return items;
    },
    addProcedure(item) {
      this.form.procedures.push({
        // id: item.id,
        code: item.code,
        name: item.name,
        date: '',
        result: 'pending',
      });
      this.procedure = null;
    },
    removeProcedure(idx) {
      this.form.procedures.splice(idx, 1);
    },
    addRiskFactor() {
      if (this.riskFactor.value) {
        this.riskFactor.value.split(/[;|]/)
          .map(v => v.trim())
          .filter(v => !!v)
          .forEach((value) => {
            this.form.riskFactors.push({
              type: this.riskFactor.type,
              value: capitalizeName(value),
            });
          });
        this.riskFactor.value = '';
      }
    },
    removeRiskFactor(idx) {
      this.form.riskFactors.splice(idx, 1);
    },
    async apiGetCompanyList(params) {
      const { data } = await this.$http.get('/clients', { params });
      return data;
    },
    async apiGetEmployeeList(params) {
      const { data } = await this.$http.get('/patients/search', { params });
      return data;
    },
    async apiGetProfessionalList(params) {
      const { data } = await this.$http.get('/professionals', { params });
      data.items = data.items.map(item => ({
        id: item.id,
        name: item.name,
        council: item.professional.councils.length > 0
          ? item.professional.councils[0]
          : null,
      }));
      return data;
    },
    async apiGetProcedureList(params) {
      const { data } = await this.$http.get('/tuss', { params });
      return data;
    },
    getEmployeeIdentity(type) {
      const found = this.form.employee.identities.find(item => item.type === type);
      return found ? found.value : null;
    },
    setEmployeeIdentity(type, value) {
      const found = this.form.employee.identities.find(item => item.type === type);
      if (found) {
        found.value = value;
      } else {
        this.form.employee.identities.push({ type, value });
      }
    },
    blankEmployeeForm() {
      return {
        id: '',
        identities: [],
        name: '',
        birthDate: '',
        gender: '',
        sector: '',
        position: '',
      };
    },
    blankForm() {
      return {
        id: '',
        date: moment().format('YYYY-MM-DD'),
        type: ADMISSION,
        status: 'pending',
        able: false,
        professional: null,
        coordinator: null,
        company: null,
        employee: this.blankEmployeeForm(),
        procedures: [],
        riskFactors: [],
        ableWithRestrictions: false,
        ableForHeight: false,
        observation: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.aso-page {
  .table {
    background-color: $gray-color-ultra-light;
    margin-top: $layout-spacing-sm;
  }
  .empty {
    margin-top: $layout-spacing-sm;
    padding: $layout-spacing-lg;
  }
}
</style>
