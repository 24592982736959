<template>
  <table class="table table-hover aso-risk-factor-table">
    <thead>
    <tr>
      <th style="width:100px;">Tipo</th>
      <th>Riscos</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="({ type, name, items }) in groupedItems" :key="type">
      <td>{{ name }}</td>
      <td>
        <span
          class="chip"
          v-for="item in items"
          :key="item.idx"
        >
          {{ item.value }}
          <a
            href="#"
            class="btn btn-clear"
            aria-label="Close"
            role="button"
            @click="remove(item.idx)"
          ></a>
        </span>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import { getName } from 'src/data/aso-risk-types';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    groupedItems() {
      return this.items.reduce((acc, item, idx) => {
        let found = acc.find(({ type }) => type === item.type);

        if (!found) {
          found = {
            type: item.type,
            name: getName(item.type),
            items: [],
          };
          acc.push(found);
        }

        found.items.push({
          ...item,
          idx,
        });

        return acc;
      }, []);
    },
  },
  methods: {
    remove(idx) {
      this.$emit('remove', idx);
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.aso-risk-factor-table {
  .chip {
    border: $border-width solid $info-color;
    &:hover {
      .btn {
        color: $error-color;
      }
    }
  }
}
</style>
