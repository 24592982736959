import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export function parsePeriod(value, options = {}) {
  options = {
    format: 'YYYY-MM-DD',
    ...options,
  };

  const date = moment();

  const period = {
    start: date,
    end: date,
  };

  if (['week', 'month', 'year'].includes(value)) {
    period.start = date.clone().startOf(value);
    period.end = date.clone().endOf(value);
  } else if (/^d:[+-]?\d+$/.test(value)) {
    const days = Number(value.replace(/^d:/, ''));
    period[days < 0 ? 'start' : 'end'] = date.clone().add(days, 'day');
  }

  period.start = options.format
    ? period.start.format(options.format)
    : period.start.toDate();
  period.end = options.format
    ? period.end.format(options.format)
    : period.end.toDate();

  return period;
}
